import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import VueRouter from 'vue-router';
import Default from './layouts/Default.vue';
import NoTabs from './layouts/NoTabs.vue';
import Blank from './layouts/Blank.vue';

Vue.component('default-layout', Default);
Vue.component('no-tabs-layout', NoTabs);
Vue.component('blank', Blank);

Vue.use(VueRouter);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
