<template>
  <div>
    <v-card class="mx-auto" max-width="900" v-if="!completedAssessment">
      <v-card-text class="pa-4">
        <v-carousel
          @change="onCarouselChange"
          height="850px"
          :continuous="false"
          v-model="currentHistory"
          hide-delimiters
          prev-icon="mdi-arrow-left-thick"
          next-icon="mdi-arrow-right-thick"
        >
          <v-carousel-item class="pt-2" v-for="(item, i) in items" :key="i">
            <history
              :showCardTitle="false"
              :historyTitle="item.historyTitle"
              :historyDescription="item.historyDescription"
              :historyPicture="item.historyPicture"
              :historyQuestion="item.historyQuestion"
              :optionA="item.optionA"
              :optionB="item.optionB"
              :optionC="item.optionC"
              :showBackBtn="false"
              :showContinueBtn="false"
              :choice="item.option"
              @choiceSelected="onChoiceSelected"
            />
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <v-card-actions>
        <v-btn :to="`/teste/${this.$route.params.id}/instrucoes`" text>
          Voltar às instruções
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="#DCE317" v-if="showEndButton" @click.stop="endDialog = true">
          Finalizar
        </v-btn>
      </v-card-actions>
      <v-dialog persistent v-model="endDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Finalizar teste?
          </v-card-title>

          <v-card-text>
            Se você finalizar, o teste não poderá ser modificado.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="endDialog = false">
              Não
            </v-btn>

            <v-btn color="primary" text @click="onEndAssessment">
              Finalizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <p class="text-h2 text-center" v-if="completedAssessment">
      Teste Finalizado!
    </p>
    <p class="text-h3 text-center" v-if="completedAssessment">
      Obrigado pela participação!
    </p>
  </div>
</template>

<script>
import History from './History.vue';
import { api } from '../services/api.js';

export default {
  components: {
    History,
  },

  methods: {
    onCarouselChange: function(historyIndex) {
      this.$router.push({ path: `/teste/${this.$route.params.id}/historias/${historyIndex + 1}` });
    },

    onChoiceSelected: async function(option) {
      // Choice made, then append new history
      if (!this.assessment.answers[this.currentHistory].choice && this.currentHistory < 21) {
        this.items.push(this.histories[this.currentHistory + 1]);
        this.assessment.answers.push({ historyNumber: 0, choice: '' });
      }

      // Update choice in the model
      const answer = {
        historyNumber: this.currentHistory + 1,
        choice: option,
      };
      this.assessment.answers[this.currentHistory] = answer;

      // Update data on DB
      this.assessment = await api.assessments.patch(this.$route.params.id, this.assessment);
    },

    onEndAssessment: async function() {
      // Update data on DB
      this.assessment.status = 'Finalizado';
      this.assessment = await api.assessments.patch(this.$route.params.id, this.assessment);
      this.completedAssessment = true;
      this.endDialog = false;
    },
  },

  computed: {
    showEndButton: function() {
      if (this.assessment.answers) {
        if (this.assessment.answers.length === 22 && this.assessment.answers[21].choice !== '') {
          return true;
        }
      }
      return false;
    },
  },

  created: async function() {
    this.assessment = await api.assessments.get(this.$route.params.id);
    if (this.assessment.answers.length === 0) {
      this.assessment.answers.push({ historyNumber: 0, choice: '' });
    }
    this.assessment.answers.forEach((answer, index) => {
      this.histories[index].option = answer.choice;
      this.items.push(this.histories[index]);
    });
    if (this.assessment.status === 'Finalizado') {
      this.completedAssessment = true;
    }
  },

  data() {
    return {
      endDialog: false,
      completedAssessment: false,
      assessment: {},
      currentHistory: this.$route.params.hn - 1,
      items: [],
      histories: [
        {
          historyTitle: 'HISTÓRIA 1 DE 22',
          historyDescription:
            'NINA E SUA IRMÃ JOANA, FORAM A UMA LOJA COM A MÃE PARA ESCOLHER UM PRESENTE DE ANIVERSÁRIO. NINA ESCOLHEU UM BRINQUEDO QUE GOSTARIA DE GANHAR HÁ MUITO TEMPO. JOANA ESCOLHEU OUTRO BRINQUEDO DIFERENTE E FALOU PARA NINA: “O MEU É MAIS CARO E MAIS LEGAL DO QUE O SEU!” NINA PENSOU SE DEVERIA TROCAR O BRINQUEDO OU FICAR COM AQUELE QUE QUERIA GANHAR HÁ TANTO TEMPO.',
          historyPicture: 'historia1.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU ESCOLHERIA O BRINQUEDO MAIS CARO.',
          optionB: 'B)	EU FICARIA COM O BRINQUEDO QUE EU QUERIA GANHAR HÁ TANTO TEMPO.',
          optionC: 'C)	EU COMPARARIA PARA VER QUAL BRINQUEDO ERA MAIS LEGAL.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 2 DE 22',
          historyDescription:
            'NINO FICOU SABENDO QUE O TIME DE VÔLEI DA ESCOLA TEM UMA VAGA. ELE QUERIA MUITO PARTICIPAR DO TIME.',
          historyPicture: 'historia2.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU CONVERSARIA COM O TREINADOR, TENTARIA PARTICIPAR E AINDA TREINARIA EM CASA, PARA JOGAR MUITO BEM.',
          optionB: 'B)	EU PERGUNTARIA PARA O TREINADOR SE EU PODERIA PARTICIPAR DO TIME.',
          optionC: 'C)	EU IA TENTAR PARTICIPAR, MAS SE FOSSE DIFÍCIL EU SAIRIA DO TIME.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 3 DE 22',
          historyDescription:
            'DUAS AMIGAS DE NINA ESTAVAM BRIGANDO E DISCUTINDO. NINA PERCEBEU QUE SUAS AMIGAS ESTAVAM FICANDO CADA VEZ MAIS BRAVAS.',
          historyPicture: 'historia3.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU TENTARIA ENTENDER PORQUÊ ELAS ESTAVAM BRIGANDO PARA AJUDA-LAS A PARAREM DE BRIGAR.',
          optionB: 'B)	EU FARIA DE CONTA QUE NÃO PERCEBI A BRIGA.',
          optionC: 'C)	EU TENTARIA SEPARA-LAS PARA NÃO CONTINUAREM BRIGANDO.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 4 DE 22',
          historyDescription:
            'DURANTE AS FÉRIAS ESCOLARES, NINO BRINCOU COM SEUS AMIGOS, COM JOGOS E LEU ALGUNS LIVROS. SEU AMIGO, CHAMADO MÁRIO CONTOU PARA ELE QUE DURANTE AS FÉRIAS FEZ OUTRAS COISAS DIFERENTES, COMO PASSEIOS AO ZOOLÓGICO E AO CINEMA. NINO ESTÁ EM DÚVIDA SE SUAS FÉRIAS FORAM TÃO DIVERTIDAS QUANTO AS FÉRIAS DO AMIGO.',
          historyPicture: 'historia4.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU PENSARIA QUE AS MINHAS FÉRIAS FORAM DIVERTIDAS, MAS NAS PRÓXIMAS FÉRIAS, TENTARIA FAZER AS COISAS QUE O MEU AMIGO FEZ.',
          optionB: 'B)	EU PENSARIA QUE AS MINHAS FÉRIAS FORAM DIVERTIDAS E QUE CADA UM TEM A SUA.',
          optionC: 'C)	EU PENSARIA QUE AS FÉRIAS DO MEU AMIGO FORAM MAIS DIVERTIDAS QUE AS MINHAS.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 5 DE 22',
          historyDescription:
            'A MÃE DE NINA PRECISOU SAIR E, POR ISSO, ELA FICOU SOZINHA EM CASA. ALGUM TEMPO DEPOIS, NINA ESCUTOU UM BARULHO DIFERENTE EM SUA CASA.',
          historyPicture: 'historia5.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU ME ESCONDERIA NO MEU QUARTO E FICARIA COM O TELEFONE NA MÃO.',
          optionB: 'B)	EU TENTARIA DESCOBRIR O QUE ERA.',
          optionC: 'C)	EU TRANCARIA AS PORTAS E LIGARIA PARA MINHA MÃE OU PARA ALGUM OUTRO ADULTO.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 6 DE 22',
          historyDescription:
            'EDUCAÇÃO ARTÍSTICA É A MATÉRIA PREFERIDA DE NINO. DURANTE A AULA, SUA PROFESSORA DESTA MATÉRIA, PERGUNTOU SE ALGUNS VOLUNTÁRIOS PODERIAM FAZER UMA PINTURA PARA A EXPOSIÇÃO DA ESCOLA.',
          historyPicture: 'historia6.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU DIRIA QUE QUERIA PARTICIPAR DA EXPOSIÇÃO E PROCURARIA INFORMAÇÕES SOBRE O TEMA PARA PODER FAZER O MEU MELHOR.',
          optionB:
            'B)	EU DIRIA QUE GOSTARIA DE PARTICIPAR DA EXPOSIÇÃO COM ALGUMA PINTURA QUE EU JÁ TINHA FEITO.',
          optionC: 'C)	EU ESPERARIA A PROFESSORA ME PEDIR PARA PARTICIPAR.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 7 DE 22',
          historyDescription:
            'NINA ESTAVA BRINCANDO DE BOLA NA RUA COM SUAS AMIGAS. NA SUA VEZ, QUANDO FOI JOGAR, A BOLA BATEU NA JANELA DE UMA CASA E O VIDRO SE QUEBROU.',
          historyPicture: 'historia7.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	IRIA ATÉ LÁ PEDIR DESCULPAS.',
          optionB: 'B)	SAIRIA CORRENDO.',
          optionC:
            'C)	EU IRIA ATÉ LÁ E AVISARIA QUE EU QUEBREI POR ACIDENTE, E DEPOIS VOLTARIA COM A MINHA MÃE, OU OUTRO ADULTO, PARA VER COMO PODERIA CONSERTAR.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 8 DE 22',
          historyDescription:
            'NINO QUERIA SAIR PARA BRINCAR COM SEUS AMIGOS, MAS ELE AINDA NÃO TINHA FEITO SUA ATIVIDADE DE CASA.',
          historyPicture: 'historia8.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU FARIA A MINHA ATIVIDADE DE CASA COM CALMA E SE SOBRASSE ALGUM TEMPO EU SAIRIA PARA BRINCAR.',
          optionB:
            'B)	EU IRIA BRINCAR PRIMEIRO E DEPOIS VOLTARIA PARA FAZER A MINHA ATIVIDADE DE CASA.',
          optionC:
            'C)	EU FARIA A MINHA ATIVIDADE DE CASA O MAIS RÁPIDO QUE CONSEGUISSE PARA PODER SAIR PARA BRINCAR.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 9 DE 22',
          historyDescription:
            'PARA FAZER PARTE DO GRUPO DE CRIANÇAS DA SUA RUA, NINA FOI DESAFIADA PELOS AMIGOS A FAZER ALGO QUE ELA ACREDITA NÃO SER BOM.',
          historyPicture: 'historia9.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU FARIA O QUE O GRUPO ME PEDIU.',
          optionB:
            'B)	EU NÃO FARIA E CONVERSARIA COM MINHA FAMÍLIA OU ALGUM ADULTO SOBRE O DESAFIO.',
          optionC: 'C)	EU TENTARIA CONVERSAR COM OS AMIGOS E VER SE PODERIA FAZER OUTRA COISA.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 10 DE 22',
          historyDescription:
            'ACONTECEU ALGUMA COISA NO TRABALHO DA MÃE DE NINO. POR CAUSA DISSO, A MÃE CHEGOU MUITO BRAVA EM CASA.',
          historyPicture: 'historia10.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU ME PREOCUPARIA E TENTARIA CONVERSAR COM ELA, OU AJUDARIA COM ALGUMA COISA.',
          optionB: 'B)	EU NÃO ME PREOCUPARIA E DEIXARIA ELA QUIETA.',
          optionC: 'C)	EU ME PREOCUPARIA E PEDIRIA DESCULPAS.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 11 DE 22',
          historyDescription:
            'NINA ESTAVA ORGANIZANDO SEU MATERIAL E PERCEBEU QUE PERDEU O LIVRO QUE PEGOU EMPRESTADO DA BIBLIOTECA.',
          historyPicture: 'historia11.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU FALTARIA NO DIA DE ENTREGAR O LIVRO.',
          optionB:
            'B)	EU CONVERSARIA COM A PROFESSORA E COM A MINHA FAMÍLIA, E COMPRARIA UM OUTRO LIVRO PARA BIBLIOTECA DA ESCOLA.',
          optionC: 'C)	EU PROCURARIA O LIVRO EM MINHA CASA.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 12 DE 22',
          historyDescription:
            'NINO TEM UMA ATIVIDADE DE CASA PARA FAZER EM GRUPO COM SEUS AMIGOS. ELES IRÃO PARA A CASA DO NINO. ELE GOSTARIA MUITO DE APROVEITAR A PRESENÇA DOS AMIGOS E BRINCAR.',
          historyPicture: 'historia12.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU FALARIA PARA GENTE FAZER A ATIVIDADE DE CASA BEM RÁPIDO, PARA TER MAIS TEMPO PARA BRINCAR.',
          optionB:
            'B)	EU FALARIA PARA A GENTE FAZER PRIMEIRO A ATIVIDADE DE CASA COM CALMA, E DEPOIS A GENTE PODERIA APROVEITAR PARA BRINCAR.',
          optionC: 'C)	EU FALARIA PARA A GENTE BRINCAR PRIMEIRO E DEPOIS FAZER A ATIVIDADE DE CASA.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 13 DE 22',
          historyDescription:
            'ESTÁ CHEGANDO O PERÍODO DE PROVAS NA ESCOLA DE NINA. ESSAS PROVAS SÃO MUITO IMPORTANTES.',
          historyPicture: 'historia13.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU NÃO ESTUDARIA.',
          optionB:
            'B)	EU ESTUDARIA BASTANTE, COMEÇARIA LENDO AS MATÉRIAS, FAZENDO OS EXERCÍCIOS, SE TIVESSE ALGUMA DÚVIDA, FALARIA COM A PROFESSORA E FARIA MEU MELHOR NAS PROVAS.',
          optionC: 'C)	EU ESTUDARIA PARA RELEMBRAR AS COISAS E FARIA AS PROVAS.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 14 DE 22',
          historyDescription:
            'A AVÓ DE NINO TINHA UM OBJETO QUE ELA DIZIA SER MUITO ESPECIAL. NINO SABIA QUE NÃO DEVERIA BRINCAR COM ELE, MAS O PEGOU ESCONDIDO. SEM QUERER, ELE ACABOU QUEBRANDO O OBJETO ESPECIAL DA SUA AVÓ.',
          historyPicture: 'historia14.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU CONSERTARIA SEM CONTAR PARA A MINHA VÓ.',
          optionB:
            'B)	EU CONTARIA PARA A MINHA VÓ E TENTARIA CONSERTAR OU COMPRAR UM NOVO PARA ELA.',
          optionC: 'C)	EU ESCONDERIA O OBJETO QUEBRADO.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 15 DE 22',
          historyDescription:
            'NINA ESTAVA EM UMA LOJA COM SUA FAMÍLIA, MAS SE DISTRAIU E SE PERDEU DE SEUS PAIS.',
          historyPicture: 'historia15.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU IRIA PARA A PORTA DA LOJA E FICARIA ESPERANDO MEUS PAIS LÁ.',
          optionB:
            'B)	EU PROCURARIA ALGUM FUNCIONÁRIO DA LOJA, OU SEGURANÇA PARA ME AJUDAR A ENCONTRAR MEUS PAIS.',
          optionC: 'C)	EU PROCURARIA MEUS PAIS CHAMANDO POR ELES.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 16 DE 22',
          historyDescription:
            'NINO ESTÁ FAZENDO UMA ATIVIDADE DE MATEMÁTICA NA ESCOLA. SEUS AMIGOS JÁ TERMINARAM A ATIVIDADE E ESTÃO CONVERSANDO ALTO, ATRAPALHANDO SUA CONCENTRAÇÃO.',
          historyPicture: 'historia16.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU FALARIA COM ELES E COM A PROFESSORA PEDINDO AJUDA PARA DIMINUIR A CONVERSA, POIS QUERIA TERMINAR MINHA ATIVIDADE.',
          optionB: 'B)	EU SÓ PEDIRIA SILÊNCIO E CONTINUARIA FAZENDO A ATIVIDADE.',
          optionC:
            'C)	EU COMEÇARIA A CONVERSAR COM ELES E FARIA A ATIVIDADE DEPOIS, PORQUE NÃO ESTAVA CONSEGUINDO ME CONCENTRAR.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 17 DE 22',
          historyDescription:
            'A PROFESSORA DE NINA PEDIU QUE TODOS OS ALUNOS FIZESSEM UMA REDAÇÃO SOBRE SUA VIDA. ANTES DE ESCREVER, NINA PENSOU EM TODAS SITUAÇÕES FELIZES E TRISTES QUE JÁ VIVEU.',
          historyPicture: 'historia17.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU PENSARIA QUE AS SITUAÇÕES TRISTES JÁ PASSARAM E QUE EU PRECISARIA PENSAR NAS COISAS FELIZES.',
          optionB: 'B)	EU PENSARIA QUE NA MINHA VIDA ACONTECEM MAIS COISAS FELIZES DO QUE TRISTES.',
          optionC: 'C)	EU NÃO GOSTO DE PENSAR NAS COISAS QUE JÁ VIVI.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 18 DE 22',
          historyDescription:
            'NINO ESTAVA PROVOCANDO SUA IRMÃ E PERCEBEU QUE ELA ESTAVA FICANDO MUITO BRAVA COM SUAS PROVOCAÇÕES.',
          historyPicture: 'historia18.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU PERCEBERIA QUE A IRMÃ ESTAVA FICANDO BRAVA, PEDIRIA DESCULPAS E PARARIA.',
          optionB: 'B)	EU ACHARIA ENGRAÇADO VER A IRMÃ BRAVA E CONTINUARIA PROVOCANDO.',
          optionC: 'C)	EU PERCEBERIA QUE A IRMÃ ESTAVA BRAVA E PARARIA.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 19 DE 22',
          historyDescription:
            'NINA ESTÁ EM CASA ESTUDANDO PARA A PROVA DE AMANHÃ, MAS SEUS AMIGOS ESTÃO CHAMANDO PARA JOGAREM BASQUETE.',
          historyPicture: 'historia19.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA:
            'A)	EU FALARIA PARA OS AMIGOS QUE EU PRECISAVA ESTUDAR PARA A PROVA DE AMANHÃ E POR ISSO NÃO PODERIA IR JOGAR BASQUETE.',
          optionB:
            'B)	EU ESTUDARIA O MAIS RÁPIDO QUE CONSEGUISSE PARA PODER JOGAR BASQUETE COM OS AMIGOS.',
          optionC: 'C)	EU JOGARIA BASQUETE COM OS AMIGOS E ESTUDARIA DEPOIS.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 20 DE 22',
          historyDescription:
            'NINO FICOU DOENTE NA PRIMEIRA SEMANA DE AULA, E POR ISSO FALTOU À ESCOLA. ELE ACABOU FICANDO COM ATIVIDADES ATRASADAS.',
          historyPicture: 'historia20.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU ESTAVA DOENTE, ENTÃO NÃO FARIA AS ATIVIDADES ATRASADAS.',
          optionB:
            'B)	EU ESPERARIA MELHORAR E QUANDO VOLTASSE PARA A ESCOLA, EU PULARIA AS FOLHAS NO CADERNO E COPIARIA AS ATIVIDADES ATRASADAS.',
          optionC:
            'C)	EU PEDIRIA PARA A MINHA MÃE CONVERSAR COM A PROFESSORA E PEGAR AS ATIVIDADES QUE EU PERDI, E IRIA FAZENDO AOS POUCOS PARA NÃO FICAR ATRASADO.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 21 DE 22',
          historyDescription:
            'NINA FOI CONVIDADA PARA BRINCAR NA CASA DE UMA AMIGA. SUA MÃE DEIXOU, MAS UM POUCO ANTES DE IR, SUA AMIGA LHE AVISOU QUE SEUS PAIS NÃO ESTARÃO EM CASA E QUE ELAS FICARÃO SOZINHAS.',
          historyPicture: 'historia21.jpg',
          historyQuestion: 'SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU AVISARIA A MINHA MÃE, OU ALGUÉM DA MINHA FAMÍLIA, QUE FICAREMOS SOZINHOS.',
          optionB: 'B)	EU IRIA COM A MINHA AMIGA PARA A CASA DELA.',
          optionC:
            'C)	EU PERGUNTARIA PARA A MINHA AMIGA SE A GENTE PODERIA IR NA MINHA CASA E OUTRO DIA NÓS IRIAMOS PARA A CASA DELA.',
          option: '',
        },
        {
          historyTitle: 'HISTÓRIA 22 DE 22',
          historyDescription:
            'NINO QUER JOGAR VÔLEI COM OS AMIGOS, MAS PARTE DO GRUPO QUER JOGAR BASQUETE.',
          historyPicture: 'historia22.jpg',
          historyQuestion: 'SE VOCÊ FOSSE O NINO, O QUE VOCÊ FARIA?',
          optionA: 'A)	EU JOGARIA VÔLEI, PORQUE É O QUE EU QUERO JOGAR.',
          optionB: 'B)	EU DESISTIRIA DE JOGAR QUALQUER UM DOS ESPORTES.',
          optionC: 'C)	EU COMBINARIA DE TODO MUNDO JOGAR UM POUCO DOS DOIS ESPORTES.',
          option: '',
        },
      ],
    };
  },
};
</script>
