<template>
  <v-container>
    <Histories />
  </v-container>
</template>

<script>
import Histories from '../components/Histories.vue';

export default {
  components: {
    Histories,
  },
};
</script>
