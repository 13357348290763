<template>
  <div>
    <v-card class="elevation-10 mx-auto my-16" max-width="500">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text"><h4>Login</h4></v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-form>
          <v-text-field
            label="E-mail"
            type="text"
            v-model="email"
          ></v-text-field>
          <v-text-field
            label="Senha"
            type="password"
            v-model="password"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="onLogin">Login</v-btn>
      </v-card-actions>
    </v-card>
    <v-alert
      class="mx-auto"
      max-width="500"
      dense
      outlined
      type="error"
      v-show="showError"
    >
      E-mail ou senha inválidos.
    </v-alert>
  </div>
</template>

<script>
import { api } from "../services/api.js";

export default {
  data: () => ({
    email: "",
    password: "",
    showError: false,
  }),
  methods: {
    onLogin: async function () {
      const result = await api.login.post({
        email: this.email,
        password: this.password,
      });
      if (result.auth) {
        localStorage.token = result.token;
        this.showError = false;
        this.$router.push({ path: "/testes" });
      } else {
        localStorage.token = null;
        this.showError = true;
      }
    },
  },
  mounted() {
    const that = this;
    window.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        that.onLogin();
      }
    });
  },
};
</script>
