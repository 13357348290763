import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#A31828',
        primary: '#B0B612',
        secondary: '#DB0723',
        // accent: '#8c9eff',
        // error: '#b71c1c',
      },
    },
  },
});
