<template>
  <v-container>
    <Registration />
  </v-container>
</template>

<script>
import Registration from '../components/Registration.vue';

export default {
  components: {
    Registration,
  },
};
</script>
