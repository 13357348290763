<template>
  <div>
    <v-app>
      <v-app-bar app color="primary" dark>
        <v-spacer></v-spacer>
        <v-app-bar-title class="title"><div>Marcadores de Resiliência Infantil</div></v-app-bar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-main class="my-6">
        <slot />
      </v-main>
      <CopyrightFooter />    
    </v-app>
  </div>
</template>

<script>
import CopyrightFooter from '../components/CopyrightFooter.vue';

export default {
    components: { CopyrightFooter }
}
</script>