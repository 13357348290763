import axios from 'axios';

class BaseApiService {
  baseUrl = process.env.VUE_APP_API_URL;
  resource;

  constructor(resource) {
    if (!resource) throw new Error('Resource is not provided');
    this.resource = resource;
  }

  getUrl(id = '') {
    return `${this.baseUrl}/${this.resource}/${id}`;
  }

  getResource() {
    return this.resource;
  }

  handleErrors(err) {
    console.error({ message: 'API Error', err });
    throw new Error(err);
  }
}

class ReadOnlyApiService extends BaseApiService {
  constructor(resource) {
    super(resource);
  }
  async fetch(config = {}) {
    try {
      const response = await axios.get(this.getUrl(), config);
      return response.data;
    } catch (err) {
      this.handleErrors(err);
    }
  }
  async get(id) {
    try {
      if (!id) throw Error('Id is not provided');
      const response = await axios.get(this.getUrl(id));
      return response.data.document;
    } catch (err) {
      this.handleErrors(err);
    }
  }
}

class ModelApiService extends ReadOnlyApiService {
  constructor(resource) {
    super(resource);
  }
  async post(data = {}, config = {}) {
    try {
      const response = await axios.post(this.getUrl(), data, config);
      if (this.getResource() === 'login') {
        return response.data;
      } else {
        // const { _id: id } = response.data.newDocument;
        // return id;
        return response.data;
      }
    } catch (err) {
      this.handleErrors(err);
    }
  }
  async patch(id, data = {}) {
    if (!id) throw Error('Id is not provided');
    try {
      const response = await axios.patch(this.getUrl(id), data);
      return response.data.updatedDocument;
    } catch (err) {
      this.handleErrors(err);
    }
  }
  async delete(id, config = {}) {
    if (!id) throw Error('Id is not provided');
    try {
      const response = await axios.delete(this.getUrl(id), config);
      return response.data;
    } catch (err) {
      this.handleErrors(err);
    }
  }
}

class AssessmentsApiService extends ModelApiService {
  constructor() {
    super('assessments');
  }
}

class LoginApiService extends ModelApiService {
  constructor() {
    super('login');
  }
}

export const api = {
  assessments: new AssessmentsApiService(),
  login: new LoginApiService(),
};
