<template>
  <v-container>
    <v-img class="opacity mx-auto" src="../assets/home.jpg" width="600"></v-img>
        <v-container class="text-center">
      <v-btn @click="onResearchPress" class="mx-auto my-16" >Pesquisa</v-btn>
    </v-container>
  </v-container>
</template>

<script>

export default {
  methods: {
    onResearchPress: function() {
      this.$router.push({ path: '/responder' });
    },
  },
};
</script>

<style scoped>
.opacity {
  opacity: 0.6;
}
</style>
