<template>
  <div>
    <v-app>
      <v-app-bar app>
        <v-spacer></v-spacer>
        <v-app-bar-title class="title"><div>Marcadores de Resiliência Infantil</div></v-app-bar-title>
        <v-spacer></v-spacer>
        <template class="tab" v-slot:extension>
          <v-tabs class="tab" color="black" center>
            <v-tab to="/">
              Home
            </v-tab>
            <v-tab to="/testes" v-show="false">
              Testes
            </v-tab>
            <v-tab to="/responder">
              Pesquisa
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
      <v-main class="my-6">
        <slot />
      </v-main>
      <CopyrightFooter />
    </v-app>
  </div>
</template>

<script>
import CopyrightFooter from "../components/CopyrightFooter.vue";
export default {
  components: {
    CopyrightFooter
}
}
</script>

<style scoped>
.tab {
  background-color: #dce317;
}
</style>
