<template>
  <div>
    <v-container>
      <p>Prezados pais e/ou responsáveis,</p>

      <p>Gostaríamos de solicitar sua autorização para que seu(a) filho(a) participe da pesquisa “Avaliação da resiliência infantil:
        estudos psicométricos para informatização e para ampliação de faixa etária do instrumento” desenvolvida pela Profª
        Drª Karina da Silva Oliveira, que é membro do Laboratório de Avaliação e Intervenção na Saúde (LAVIS). <b>Os
          objetivos desta pesquisa são adaptar um teste psicológico chamado “Marcadores de Resiliência Infantil” para que
          seja possível utilizá-lo em formato digital e ampliar a faixa etária de utilização, que originalmente é de 8 a
          12 anos, passando a incluir crianças a partir de 5 anos.</b> É importante compartilhar que o teste já é aprovado
        para uso profissional pelo Conselho Federal de Psicologia.</p>

      <p><b>A participação nesta pesquisa é voluntária</b>, isto é, não são previstas vantagens financeiras, ou custos ao
        participante. Assim, <b>você poderá autorizar e/ou retirar a autorização de seu(a) filho(a) a qualquer
          momento</b>, sem qualquer prejuízo ou penalidades. <b>Todas as informações coletadas serão sigilosas, de modo
          que nem a família, nem a criança, nem a escola será identificada em qualquer circunstância.</b></p>

      <p>Ao aceitarem este convite, seu(a) filho(a) <b>responderá ao teste psicológico “Marcadores de Resiliência
          Infantil”.</b> As respostas aos itens do teste serão dadas individualmente. <b>Os dados das respostas serão
          organizados em um banco de dados digital sigiloso.</b> A participação da criança na pesquisa ser dá por dizer o
        que a personagem da história deve fazer em situações cotidianas como escolher uma brincadeira, um brinquedo ou o
        tipo de atividade escolar. <b>Por isso os riscos de participação são os mesmos esperados para atividades como
          leitura de livros infantis e contação de histórias, como por exemplo, o cansaço. Caso isso aconteça, a criança
          poderá interromper a participação livremente.</b> Ainda, se qualquer outro desconforto for experimentado durante
        a participação, será oferecido acolhimento psicológico sem custo ao participante, bem como será feito o
        encaminhamento para atendimento especializado, caso seja necessário. <b>Vocês estão amparados pela Resolução nº
          466 de 12 de dezembro de 2012 do Ministério da Saúde, podendo solicitar suporte nos termos descritos na referida
          resolução.</b></p>

      <p>Caso autorize que seu(a) filho(a) participe desta pesquisa por favor, selecione a opção correspondente abaixo.
        <b>Sugerimos que você guarde uma cópia deste termo com você.</b>
      </p>

      <p>Este estudo foi aprovado pelo Comitê de Ética em Pesquisa da Universidade Federal de Minas Gerais, com o número
        CAAE 51537621.0.0000.5149. Se você tiver dúvidas durante a condução da pesquisa, ou mesmo depois dela ter se
        encerrado, poderá entrar em contato para esclarecê-las com a pesquisadora Profª Drª Karina da Silva Oliveira, pelo
        e-mail <a href="mailto: karinaoliveira@ufmg.br">karinaoliveira@ufmg.br</a> ou <a
          href="mailto: oliveira.karinadasilva@gmail.com">oliveira.karinadasilva@gmail.com</a>. Questões de ordem ética
        poderão ser consultadas junto ao Comitê de Ética através do número (31) 3409-4592, e/ou e-mail: coep@prpq.ufmg.br,
        endereço Av. Antônio Carlos, 6627. Unidade Administrativa II - 2º andar - Sala 2005. Campus Pampulha. Belo
        Horizonte, MG – Brasil. CEP: 31270-901.</p>
      <p class="center-p">Agradecemos a colaboração.</p>
      <p class="center-p">Karina da Silva Oliveira</p>
      <p class="center-p">Professora do Departamento de Psicologia e do Programa de Pós-Graduação em Psicologia: Cognição
        e Comportamento</p>
      <p class="center-p">Universidade Federal de Minas Gerais</p>
      <p class="center-p"><a href="mailto: karinaoliveira@ufmg.br">karinaoliveira@ufmg.br</a></p>
      <v-checkbox color="primary" v-model="enabled"
        label="Declaro estar ciente da pesquisa a ser realizada e autorizo a participação de meu(a) filho(a) na mesma."></v-checkbox>
    </v-container>
    <v-container class="text-center">
      <v-btn :disabled="!enabled || loading" @click="onNewAssessment" class="mx-auto my-2" color="#DCE317">Iniciar</v-btn>
      <v-progress-linear v-show="loading" indeterminate></v-progress-linear>
      <v-alert dense outlined type="error" v-show="showError && !loading">
        Erro na conexão com o banco de dados.
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import { api } from "../services/api.js";

export default {
  data: () => ({
    assessment: {
      user: "",
      patient: {
        name: "",
        schoolYear: "",
        schoolName: "",
        schoolType: "",
        dateOfBirth: "",
        gender: "",
        region: "",
      },
      screenWidth: screen.width,
      screenHeight: screen.height,
    },
    showError: false,
    loading: false,
    enabled: false
  }),
  methods: {
    onNewAssessment: async function () {
      this.loading = true;
      try {
        const config = {
          headers: {
            "X-Auth-Token": 'SELF-TEST',
          },
        };
        const result = await api.assessments.post(this.assessment, config);
        if (result.newDocument) {
          const assessmentId = result.newDocument._id;
          this.loading = false;
          this.$router.push({ path: `/teste/${assessmentId}` });
        } else {
          console.log(result);
        }
        this.loading = false;
        this.showError = false;
      } catch (error) {
        this.loading = false;
        this.showError = true;
      }
    }
  },
};
</script>

<style scoped>
.center-p {
  margin-bottom: 3px;
  text-align: center;
}
</style>