<template>
  <div>
    <v-app>
      <v-main>
        <v-container fluid fill-height class="loginOverlay">
          <v-layout flex align-center justify-center>
            <v-flex xs12 sm4 elevation-6>
              <slot />
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
      <CopyrightFooter />
    </v-app>    
  </div>
</template>

<script>
import CopyrightFooter from '../components/CopyrightFooter.vue';

export default {
    components: { CopyrightFooter }
}
</script>