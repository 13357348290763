import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Assessments from '../views/Assessments.vue';
import Assessment from '../views/Assessment.vue';
import Instructions from '../views/Instructions.vue';
import Example from '../views/Example.vue';
import Histories from '../views/Histories.vue';
import Login from '../views/Login.vue';
import Answer from '../views/Answer.vue';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/responder', name: 'Answer', meta: { layout: 'no-tabs'}, component: Answer },
  { path: '/testes', name: 'Assessments', component: Assessments },
  { path: '/teste/:id', name: 'Assessment', meta: { layout: 'no-tabs' }, component: Assessment },
  { path: '/login', name: 'Login', meta: { layout: 'no-tabs' }, component: Login },
  {
    path: '/teste/:id/instrucoes',
    name: 'Instructions',
    meta: { layout: 'no-tabs' },
    component: Instructions,
  },
  {
    path: '/teste/:id/exemplo',
    name: 'Example',
    meta: { layout: 'no-tabs' },
    component: Example,
  },
  {
    path: '/teste/:id/historias/:hn',
    name: 'Histories',
    meta: { layout: 'no-tabs' },
    component: Histories,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
