<template>
  <div>
    <history
      @backFromHistory="onBackFromHistory"
      @continueHistory="onContinueHistory"
      showCardTitle
      cardTitle="Exemplo"
      historyTitle="HISTÓRIA"
      historyDescription="A NINA ESTÁ MUITO TRISTE, POIS DESCOBRIU QUE MARIA, SUA MELHOR AMIGA, IRÁ MORAR EM OUTRA
          CIDADE."
      historyPicture="exemplo.jpg"
      historyQuestion="SE VOCÊ FOSSE A NINA, O QUE VOCÊ FARIA?"
      optionA="A) EU SENTIRIA SAUDADES, MAS TENTARIA ENCONTRAR UM NOVO MELHOR AMIGO BEM RÁPIDO."
      optionB="B)	EU NÃO SENTIRIA NADA, EU IA ME DESPEDIR E ARRUMAR UM OUTRO AMIGO."
      optionC="C)	EU FICARIA TRISTE, MAS TENTARIA COMBINAR UMA FORMA DE MANTER CONTATO, POR EXEMPLO, VISITAR, LIGAR, OU MANDAR MENSAGEM."
      showBackBtn
      backBtnText="Voltar"
      showContinueBtn
      continueBtnText="Iniciar Teste"
    />
  </div>
</template>

<script>
import History from './History.vue';

export default {
  components: {
    History,
  },
  methods: {
    onBackFromHistory: function() {
      this.$router.push({ path: `/teste/${this.$route.params.id}/instrucoes` });
    },
    onContinueHistory: function() {
      this.$router.push({ path: `/teste/${this.$route.params.id}/historias/1` });
    },
  },
};
</script>
