<template>
  <v-container>
    <Instructions />
  </v-container>
</template>

<script>
import Instructions from '../components/Instructions.vue';

export default {
  components: {
    Instructions,
  },
};
</script>
