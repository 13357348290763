<template>
  <div>
    <v-card class="mx-auto" max-width="700" elevation="3">
      <v-card-title class="pl-10 pt-5">
        Instruções
      </v-card-title>
      <v-card-text class="pa-10">
        <p class="font-weight-medium">OLÁ,</p>
        <p class="font-weight-medium">
          NAS PRÓXIMAS PÁGINAS, VOCÊ ENCONTRARÁ PEQUENAS HISTÓRIAS ONDE OS PERSONAGENS PRINCIPAIS
          SEMPRE SERÃO A NINA OU O NINO, ELES SÃO CRIANÇAS COMO VOCÊ.
        </p>
        <p class="font-weight-medium">
          EM CADA HISTÓRIA, ELES ESTARÃO EM UMA SITUAÇÃO DIFERENTE, E VOCÊ DEVERÁ ASSINALAR QUAL A
          OPÇÃO (A, B OU C) ACREDITA SER MAIS PRÓXIMA DE COMO VOCÊ AGIRIA SE ESTIVESSE NA MESMA
          SITUAÇÃO DELES.
        </p>
        <p class="font-weight-medium">
          AS SUAS RESPOSTAS DEVERÃO SER SELECIONADAS NA PÁGINA DA HISTÓRIA. DEPOIS QUE SELECIONAR
          SUA RESPOSTA, PARA LER A PRÓXIMA HISTÓRIA, CLIQUE EM: <v-img class="img" max-width="40" max-heigth="40" :src="require('@/assets/next-icon.jpg')"></v-img>
          FAÇA ISSO DE NOVO E DE NOVO ATÉ COMPLETAR AS HISTÓRIAS.
        </p>
        <p class="font-weight-medium">
          SE QUISER VOLTAR PARA AS HISTÓRIAS ANTERIORES CLIQUE EM: <v-img class="img" max-width="40" max-heigth="40" :src="require('@/assets/prev-icon.jpg')"></v-img>
        </p>
        <p class="font-weight-medium">
          AO FINAL, QUANDO NÃO HOUVER MAIS HISTÓRIAS PARA RESPONDER E PARA ENCERRAR O TESTE, É MUITO IMPORTANTE CLICAR EM: <v-img class="img" max-width="90" max-heigth="90" :src="require('@/assets/end-button.jpg')"></v-img>
        </p>
        <p class="font-weight-medium">
          É IMPORTANTE QUE VOCÊ SAIBA QUE NÃO EXISTEM RESPOSTAS CERTAS OU ERRADAS, POR ISSO LEIA A
          HISTÓRIA COM ATENÇÃO E ESCOLHA A OPÇÃO QUE SEJA MAIS PARECIDA COM O QUE VOCÊ FARIA.
        </p>
        <p class="font-weight-medium">
          SE VOCÊ NÃO TIVER DÚVIDAS, CLIQUE EM CONTINUAR.
        </p>
        <p class="font-weight-medium">
          ESPERAMOS QUE SEJA UMA ATIVIDADE DIVERTIDA!
        </p>
        <p class="text-right mr-6 mt-10">
          Karina da Silva Oliveira
        </p>
        <p class="text-right mr-6">
          Tatiana de Cássia Nakano
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn :to="`/teste/${this.$route.params.id}`" text>
          Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click.stop="agreementDialog = true" color="#DCE317">
          Continuar
        </v-btn>
      </v-card-actions>
      <v-dialog persistent v-model="agreementDialog" max-width="390">
        <v-card>
          <v-card-title class="text-h5">
            Para a criança:
          </v-card-title>

          <v-card-text class="mt-4">
            <b>Você concorda que entendeu as instruções e quer participar da pesquisa?</b>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="agreementDialog = false">
              Não
            </v-btn>

            <v-btn color="primary" text @click="onAgreed">
              Sim
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>

export default {
  data: () => ({
    agreementDialog: false
  }),
  methods: {
    onAgreed: function () {
      this.$router.push({ path: `/teste/${this.$route.params.id}/exemplo` });
    }
  }
};
</script>

<style scoped>
.img {
    vertical-align: middle; 
}
</style>