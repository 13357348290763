<template>
  <v-container>
    <Example />
  </v-container>
</template>

<script>
import Example from '../components/Example.vue';

export default {
  components: {
    Example,
  },
};
</script>
