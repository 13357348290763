<template>
  <v-container>
    <v-alert dense outlined type="error" v-show="showError">
      Erro na conexão com o banco de dados.
    </v-alert>
    <v-row>
      <v-col cols="1">
        <v-row class="mt-2 mx-auto">
          <v-btn @click="onNewAssessment" color="secondary" dark fab>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
        <v-row class="mt-6 mx-auto">
          <v-btn @click="fetchAssessements" color="#DCE317" fab>
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="11">
        <v-simple-table class="elevation-1" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Status</th>
                <th class="text-left">Gerado em</th>
                <th class="text-left">Nome da Criança</th>
                <th class="text-left">Ano Escolar</th>
                <th class="text-left">Nome da Escola</th>
                <th class="text-left">Tipo Escola</th>
                <th class="text-left">Teste</th>
                <th class="text-left">Resultado</th>
                <th class="text-left">Excluir</th>
              </tr>
            </thead>
            <tbody class="table">
              <tr v-for="assessment in assessments" :key="assessment._id">
                <td>{{ assessment.status }}</td>
                <td>{{ formatDate(assessment.createdAt) }}</td>
                <td>{{ assessment.patient.name }}</td>
                <td>{{ assessment.patient.schoolYear }}</td>
                <td>{{ assessment.patient.schoolName }}</td>
                <td>{{ assessment.patient.schoolType }}</td>
                <td>
                  <v-menu offset-x>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        small
                        dark
                        fab
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-share-variant </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link>
                        <v-btn
                          block
                          depressed
                          small
                          :href="`/teste/${assessment._id}`"
                          target="_blank"
                          >Abrir</v-btn
                        >
                      </v-list-item>
                      <v-list-item link>
                        <v-btn
                          @click="copyToClipboard(assessment._id)"
                          block
                          depressed
                          small
                          >Copiar Link</v-btn
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td>
                  <v-btn
                    small
                    fab
                    :disabled="assessment.status !== 'Finalizado'"
                    @click="onResult(assessment._id)"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    x-small
                    fab
                    :disabled="assessment.status !== 'Novo'"
                    @click="onDeleteAssessment(assessment._id)"
                  >
                    <v-icon color="#8E0011">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { api } from "../services/api.js";
import { format, parseISO } from "date-fns";
import { jsPDF } from "jspdf";

export default {
  data: () => ({
    assessments: {},
    assessment: {
      user: "",
      patient: {
        name: "",
        schoolYear: "",
        schoolName: "",
        schoolType: "",
        dateOfBirth: "",
        gender: "",
        region: "",
      },
    },
    assessmentId: "",
    snackbar: false,
    showError: false,
    text: "Link copiado.",
    timeout: 2000,
  }),
  created: async function () {
    this.fetchAssessements();
  },
  methods: {
    formatDate: function (date) {
      return date ? format(parseISO(date), "dd/MM/yyyy - HH:mm:ss") : "";
    },
    formatDateOnly: function (date) {
      const dt = parseISO(date);
      const dtDateOnly = new Date(
        dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
      );
      return date ? format(dtDateOnly, "dd/MM/yyyy") : "";
    },
    fetchAssessements: async function () {
      try {
        const config = {
          headers: {
            "X-Auth-Token": localStorage.token,
          },
        };
        const result = await api.assessments.fetch(config);
        if (result.documents) {
          this.assessments = result.documents;
          this.assessments.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        } else {
          this.assessments = {};
          console.log(result);
        }
        this.showError = false;
      } catch (error) {
        this.showError = true;
        this.$router.push({ path: "/login" });
      }
    },
    onNewAssessment: async function () {
      try {
        const config = {
          headers: {
            "X-Auth-Token": localStorage.token,
          },
        };
        const result = await api.assessments.post(this.assessment, config);
        if (result.newDocument) {
          this.assessmentId = result.newDocument._id;
          this.fetchAssessements();
        } else {
          console.log(result);
        }
        this.showError = false;
      } catch (error) {
        this.showError = true;
        this.$router.push({ path: "/login" });
      }
    },
    onDeleteAssessment: async function (id) {
      try {
        const config = {
          headers: {
            "X-Auth-Token": localStorage.token,
          },
        };
        const result = await api.assessments.delete(id, config);
        if (result.deleted) {
          this.fetchAssessements();
        } else {
          console.log(result);
        }
        this.showError = false;
      } catch (error) {
        this.showError = true;
        this.$router.push({ path: "/login" });
      }
    },
    onResult: function (id) {
      const i = this.assessments.findIndex(
        (assessment) => assessment._id === id
      );
      const doc = new jsPDF();

      const initX = 0;
      const initY = 10;

      doc.setFontSize(12);
      doc.setFont("times", "bold");
      doc.text("Data de Aplicação:", 143, 10);

      doc.setFont("times", "normal");
      doc.text(this.formatDateOnly(this.assessments[i].lastChangedAt), 180, 10);

      doc.setFontSize(16);
      doc.setFont("times", "bold");
      doc.text("MARCADORES DE RESILIÊNCIA INFANTIL", initX + 47, initY + 30);

      doc.setFontSize(14);
      doc.setFont("times", "bold");
      doc.text("Nome:", initX + 10, initY + 50);

      doc.setFont("times", "normal");
      doc.text(this.assessments[i].patient.name, initX + 28, initY + 50);

      doc.setFont("times", "bold");
      doc.text("Série:", initX + 10, initY + 60);

      doc.setFont("times", "normal");
      doc.text(this.assessments[i].patient.schoolYear, initX + 26, initY + 60);

      doc.setFont("times", "bold");
      doc.text("Escola:", initX + 50, initY + 60);

      doc.setFont("times", "normal");
      doc.text(this.assessments[i].patient.schoolName, initX + 70, initY + 60);

      doc.setFont("times", "bold");
      doc.text("Tipo Escola:", initX + 150, initY + 60);

      doc.setFont("times", "normal");
      doc.text(this.assessments[i].patient.schoolType, initX + 180, initY + 60);

      doc.setFont("times", "bold");
      doc.text("Data de Nascimento:", initX + 10, initY + 70);

      doc.setFont("times", "normal");
      doc.text(
        this.formatDateOnly(this.assessments[i].patient.dateOfBirth),
        initX + 58,
        initY + 70
      );

      doc.setFont("times", "bold");
      doc.text("Sexo:", initX + 150, initY + 70);

      doc.setFont("times", "normal");
      doc.text(this.assessments[i].patient.gender, initX + 165, initY + 70);

      doc.setFontSize(16);
      doc.setFont("times", "bold");
      doc.text("- Interpretação dos resultados", initX + 15, initY + 95);

      const initAGX = initX + 40;
      const initAGY = initY + 105;

      doc.rect(initAGX, initAGY, 120, 7);

      doc.setFontSize(14);
      doc.setFont("times", "normal");
      doc.text("AMOSTRA GERAL", initAGX + 40, initAGY + 5);

      doc.rect(initAGX, initAGY + 7, 30, 15);
      doc.text("Escore", initAGX + 8, initAGY + 13);
      doc.text("Padronizado", initAGX + 3, initAGY + 19);

      doc.rect(initAGX, initAGY + 22, 30, 15);
      doc.text(
        this.assessments[i].results[0].standardScoreZ.toString(),
        initAGX + 8,
        initAGY + 31
      );

      doc.rect(initAGX + 30, initAGY + 7, 30, 15);
      doc.text("Percentil", initAGX + 36, initAGY + 16);

      doc.rect(initAGX + 30, initAGY + 22, 30, 15);
      doc.text(
        this.assessments[i].results[0].percentile.toString(),
        initAGX + 42,
        initAGY + 31
      );

      doc.rect(initAGX + 60, initAGY + 7, 60, 15);
      doc.text("Interpretação", initAGX + 75, initAGY + 16);

      doc.rect(initAGX + 60, initAGY + 22, 60, 15);
      doc.text(
        this.assessments[i].results[0].classification,
        initAGX + 67,
        initAGY + 31
      );

      // doc.output('dataurlnewwindow', { filename: 'resultado.pdf' });
      doc.save("resultado.pdf");
    },
    copyToClipboard: function (id) {
      var input = document.body.appendChild(document.createElement("input"));
      input.value = `http://localhost:8080/teste/${id}`;
      input.focus();
      input.select();
      document.execCommand("copy");
      input.parentNode.removeChild(input);
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.table {
  background: #e5f9f7;
}
</style>
