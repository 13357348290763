<template>
  <div>
    <v-card class="mx-auto" max-width="700" elevation="3">
      <v-card-title v-show="showCardTitle" class="pl-10 pt-5">
        {{ cardTitle }}
      </v-card-title>
      <h4 class="text-center">
        {{ historyTitle }}
      </h4>
      <v-card-text class="pa-10">
        <p class="text-center font-weight-medium">
          {{ historyDescription }}
        </p>
        <v-img :src="require(`@/assets/${historyPicture}`)"></v-img>
        <p class="text-center font-weight-medium mt-6">
          {{ historyQuestion }}
        </p>
        <v-radio-group
          class="small-radio-label"
          v-model="option"
          @change="$emit('choiceSelected', option)"
        >
          <v-radio :label="optionA" value="A"> </v-radio>
          <v-radio :label="optionB" value="B"></v-radio>
          <v-radio :label="optionC" value="C"></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-btn v-show="showBackBtn" @click="$emit('backFromHistory')" text>
          {{ backBtnText }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-show="showContinueBtn" @click="$emit('continueHistory')" color="#DCE317">
          {{ continueBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    showCardTitle: Boolean,
    cardTitle: String,
    historyTitle: String,
    historyDescription: String,
    historyPicture: String,
    historyQuestion: String,
    optionA: String,
    optionB: String,
    optionC: String,
    showBackBtn: Boolean,
    backBtnText: String,
    showContinueBtn: Boolean,
    continueBtnText: String,
    choice: String,
  },

  data() {
    return {
      option: this.choice,
    };
  },
};
</script>

<style scoped>
.small-radio-label >>> label {
  font-size: 14px;
  font-weight: 500;
}
</style>
